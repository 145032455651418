import { Text, TextAs, TextVariant } from '@components/gassan-ui/Typography/Text';
import { parse } from '@config/theme';
import { FC } from 'react';
type MenuLabelProps = {
  variant?: TextVariant;
  as?: TextAs;
};
const label = parse({
  display: 'flex',
  alignItems: 'center'
}, "le7wuez");
export const MenuLabel: FC<MenuLabelProps> = ({
  variant = 'small',
  as = 'div',
  children
}) => {
  return <Text className={label} variant={variant} as={as}>
      {children}
    </Text>;
};

require("./MenuLabel.linaria.module.css!=!../../../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./MenuLabel.tsx");