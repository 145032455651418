import * as styles from './styles'
import { Text } from '@components/gassan-ui/Typography/Text'
import Portal from '@reach/portal'
import { useRouter } from 'next/router'
import { FC } from 'react'

export const ExitPreview: FC = () => {
  const { isPreview } = useRouter()
  return (
    <>
      {isPreview && (
        <Portal>
          <a href="/api/exit-preview" className={styles.buttonContainer}>
            <Text variant="micro" className="mb-0">
              Exit preview
            </Text>
          </a>
        </Portal>
      )}
    </>
  )
}
