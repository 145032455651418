// @ts-strict-ignore
import { Item } from '.'
import { Checkbox } from '../Form/Checkbox'
import { Box } from '../Layout'
import { Link } from '../Link'
import { OldText } from '../Typography'
import { FilterMenuButton } from './FilterMenuButton'
import { FilterMenuFooter } from './FilterMenuFooter'
import { Menu, MenuList } from './Menu'
import { Search } from './Search'
import Downshift from 'downshift'
import { matchSorter } from 'match-sorter'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import React, { FC, useEffect, useState } from 'react'

type Value = string | number

export type FilterMenuCheckboxProps = {
  isolatedFirstElement?: React.ReactNode
  items: Item[]
  value: Value[]
  onSelectItem: (items: Value[]) => void
  label: string
  activeFilters?: number
  lang?: string
  onClickReset?: () => void
  hasSearch?: boolean
  placeholder?: string
  noneFoundText?: string
}

export const FilterMenuCheckbox: FC<FilterMenuCheckboxProps> = ({
  isolatedFirstElement,
  items,
  value,
  onSelectItem,
  label,
  activeFilters,
  onClickReset,
  hasSearch,
  placeholder,
  noneFoundText,
}) => {
  const { t } = useTranslation('other')
  const [selected, setSelected] = useState<Value[]>(value || [])
  const [search, setSearch] = useState('')

  useEffect(() => {
    setSelected(value)
  }, [value])

  const filtered =
    search.length > 0 ? matchSorter(items, search, { keys: ['value', 'label'] }) : items

  return (
    <Downshift
      itemToString={(item) => (item ? item.value : null)}
      selectedItem={null}
      onOuterClick={() => {
        onSelectItem(selected)
        setSearch('')
      }}
    >
      {({ getToggleButtonProps, getMenuProps, getRootProps, getItemProps, isOpen, closeMenu }) => {
        return (
          <Box {...getRootProps()} position="relative" display="inline-block" mb="1rem">
            <FilterMenuButton
              {...getToggleButtonProps()}
              isExpanded={isOpen}
              activeFilters={activeFilters}
            >
              {label}
            </FilterMenuButton>
            {isOpen && (
              <Menu {...getMenuProps()} pb={rem(70)} pt={hasSearch ? rem(60) : '1rem'}>
                {hasSearch && (
                  <Search
                    placeholder={placeholder}
                    onChange={(e) => setSearch(e.target.value)}
                    value={search}
                  />
                )}
                <MenuList>
                  <>
                    {isolatedFirstElement && <li>{isolatedFirstElement}</li>}
                    {filtered.length ? (
                      filtered.map((item, index) => (
                        <li
                          key={`item-${index}`}
                          onClick={() => {
                            setSelected((state) =>
                              state.includes(item.value)
                                ? state.filter((i) => i !== item.value)
                                : [...state, item.value],
                            )
                          }}
                        >
                          <Box style={{ pointerEvents: 'none' }} as="span">
                            <Checkbox
                              id={item.value.toString()}
                              label={item.label}
                              value={item.value}
                              checked={selected.includes(item.value)}
                              readOnly
                              mb={index === items.length - 1 ? 0 : '.5rem'}
                            />
                          </Box>
                        </li>
                      ))
                    ) : (
                      <OldText as="div" color="shades.600" px="1.5rem">
                        {noneFoundText}
                      </OldText>
                    )}
                  </>
                </MenuList>
                <FilterMenuFooter>
                  <Link
                    as="button"
                    onClick={() => {
                      setSearch('')
                      onClickReset()
                    }}
                    variant="brown"
                    mr="1rem"
                  >
                    {t('reset')}
                  </Link>
                  <Link
                    as="button"
                    onClick={() => {
                      closeMenu()
                      onSelectItem(selected)
                    }}
                    variant="blue"
                    ml="1rem"
                  >
                    {t('save')}
                  </Link>
                </FilterMenuFooter>
              </Menu>
            )}
          </Box>
        )
      }}
    </Downshift>
  )
}
