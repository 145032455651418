import Column from './Column'
import LanguageDropdown from './LanguageDropdown'
import Link from './Link'
import NewsletterSignup from './NewsletterSignup'
import SocialLink from './SocialLink'
import SocialLinks from './SocialLinks'
import CookieModal from '@components/Cookies/Modal'
import LocalizedLink from '@components/Localization/LocalizedLink'
import Logo from '@components/Logo'
import { LinkTranslated } from '@components/types'
import { Container, OldHeading, Icon, breakpoints, heading3, heading4 } from '@gassan-ui'
import { cn } from '@lib/cn'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'
import styled from 'styled-components'

export const ColumnHeading = styled(OldHeading)`
  ${heading3};

  @media screen and (min-width: ${breakpoints.small}) {
    ${heading4};
  }
`

const list = '[&_li]:mb-4'

ColumnHeading.defaultProps = {
  as: 'h4',
}

interface Props {}

const Columns: FC<Props> = () => {
  const [isCookiePopupVisible, setIsCookiePopupVisible] = useState(false)
  const { t } = useTranslation(['footer-items', 'footer', 'cookies'])
  return (
    <div
      className={cn(
        'mt-12 bg-pampas pb-8',
        'sm:mt-20',
        'md:mt-36 md:pb-16',
        'md:mt-32 lg:bg-white',
        'xl:pb-24',
      )}
    >
      <div className={cn('flex h-20 items-center justify-center', 'md:h-36', 'lg:hidden')}>
        <Logo width={[90, 90, 180]} color="tabaccoBrown" />
      </div>
      <Container className={cn('grid grid-cols-1 gap-x-4', 'lg:grid-cols-4')}>
        <Column title={t('popularPages', { ns: 'footer' })} canCollapse>
          <ul className={list}>
            {t<string, LinkTranslated[]>('popularPages', {
              ns: 'footer-items',
              returnObjects: true,
            }).map((item) => (
              <li key={item.urlSlug}>
                <LocalizedLink href={`/${item.urlSlug}`}>
                  <Link>{item.title}</Link>
                </LocalizedLink>
              </li>
            ))}
          </ul>
        </Column>
        <Column title={t('popularBrands', { ns: 'footer' })} canCollapse>
          <ul className={list}>
            {t<string, LinkTranslated[]>('popularBrands', {
              ns: 'footer-items',
              returnObjects: true,
            }).map((item) => (
              <li key={item.urlSlug}>
                <LocalizedLink href={`/${item.urlSlug}`}>
                  <Link>{item.title}</Link>
                </LocalizedLink>
              </li>
            ))}
          </ul>
        </Column>
        <Column title={t('informatie', { ns: 'footer' })} canCollapse>
          <ul className={list}>
            {t<string, LinkTranslated[]>('information', {
              ns: 'footer-items',
              returnObjects: true,
            }).map((item, index) => (
              <li key={`${index}-${item.urlSlug}`}>
                <LocalizedLink href={`/${item.urlSlug}`}>
                  <Link>{item.title}</Link>
                </LocalizedLink>
              </li>
            ))}
            <li>
              <Link as="button" onClick={() => setIsCookiePopupVisible(true)}>
                {t('footerLink', { ns: 'cookies' })}
              </Link>
            </li>
          </ul>
        </Column>
        <div className={cn('border-t border-shade-400 pt-8', 'lg:border-none lg:pt-0')}>
          <ColumnHeading variant="h4" mb={{ _: 3, large: '1.5rem' }}>
            {t('followUs', { ns: 'footer' })}
          </ColumnHeading>
          <div className="mb-8">
            <SocialLinks>
              <li>
                <SocialLink
                  target="_blank"
                  href="https://www.facebook.com/gassandiamonds"
                  rel="noreferrer"
                >
                  <Icon icon="facebook" size={40} />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  target="_blank"
                  href="https://instagram.com/gassandiamonds"
                  rel="noreferrer"
                >
                  <Icon icon="instagram" size={40} />
                </SocialLink>
              </li>
              <li>
                <SocialLink
                  target="_blank"
                  href="https://www.linkedin.com/company/3067842/admin/"
                  rel="noreferrer"
                >
                  <Icon icon="linkedin" size={40} />
                </SocialLink>
              </li>
            </SocialLinks>
          </div>
          <div className="mb-8">
            <NewsletterSignup />
          </div>
          <ColumnHeading
            variant="h4"
            mb={{
              _: '0.5rem',
              small: '1rem',
            }}
          >
            {t('language', { ns: 'footer' })}
          </ColumnHeading>
          <LanguageDropdown />
        </div>
      </Container>
      <CookieModal
        onClose={() => setIsCookiePopupVisible(false)}
        isVisible={isCookiePopupVisible}
      />
    </div>
  )
}

export default Columns
