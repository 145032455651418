import { LanguageSwitchProps } from '.'
import { Link } from '../Link'
import { OldText } from '../Typography'
import { cn } from '@lib/cn'
import { FC } from 'react'

export const LanguageSwitch: FC<LanguageSwitchProps> = ({ onClickAction }) => {
  return (
    <div className={cn('grid grid-cols-[auto_auto] items-center gap-3', 'sm:gap-4')}>
      <div>
        <OldText variant="micro" as="div" mb={1}>
          Current language is
        </OldText>
        <div className="flex items-center">
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAADrSURBVHgB5ZOhDsIwEECvDAxqJCxBYkiQq0F3CcHzB+MTQPARJHwDHo8hJJvCrjgSzMQEBEEdgjWlTSagG2Ug4alr7/Laa3MAPwsyJdfQcS2wXBVz4HQAB/qRKIBum4NYyJBoqdgC5Hmwj9+KMkkkQxuKYVKGdVlFr5KSwCBR2NltX98oWa7cutOMoAQN0sMIoeI3E0L4ojw+mFr7lqq2pttdAmVgl+tTW7lfa/Xn8rERAQNCHnjaTPDjXr61tDKShcwgYShFQ30/JzqG41gWYnVqgSZUOVWjZ4wj4pCZa9WyEblxeg6nFP6PO/+FdHZB17DaAAAAAElFTkSuQmCC"
            alt=""
            style={{ marginLeft: '-0.25rem' }}
          />
          <OldText variant="regular" as="span" fontWeight={600} ml="2px" mb={0}>
            Dutch
          </OldText>
        </div>
      </div>
      <div className="flex justify-end">
        <Link variant="blue" onClick={onClickAction}>
          Switch to English
        </Link>
      </div>
    </div>
  )
}
