import { OldText } from '../Typography'
import { cn } from '@lib/cn'
import React, { FC } from 'react'
import { OldTextProps } from '../Typography/OldText'

export const NotificationLabel: FC<OldTextProps> = ({ className, ...props }) => {
  return (
    <OldText
      className={cn('block w-full overflow-hidden text-ellipsis whitespace-nowrap', className)}
      as="div"
      mb="0"
      {...props}
    />
  )
}
