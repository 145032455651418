import { Icon } from './gassan-ui';
import { Heading, Text } from '@gassan-ui';
import { parse } from '@config/theme';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
type NewAtGassanProps = {
  variant?: 'slim' | 'full';
};
export const NewAtGassan: FC<NewAtGassanProps> = ({
  variant = 'full'
}) => {
  const {
    t
  } = useTranslation('account');
  return <div className={parent}>
      {variant === 'full' && <>
          <Heading as="h3" variant="h3" className="mb-2">
            {t('newAtGassan.title')}
          </Heading>
          <Text variant="regular" className="mb-6">
            {t('newAtGassan.subtitle')}
          </Text>
        </>}
      <div>
        <div className={item}>
          <Icon icon="account-giftcard" />
          <Text variant="regular" className="mb-0">
            {t('newAtGassan.gifts')}
          </Text>
        </div>
        <div className={item}>
          <Icon icon="account-events" />
          <Text variant="regular" className="mb-0">
            {t('newAtGassan.events')}
          </Text>
        </div>
        <div className={item}>
          <Icon icon="account-privileges" />
          <Text variant="regular" className="mb-0">
            {t('newAtGassan.service')}
          </Text>
        </div>
        <div className={item}>
          <Icon icon="account-diamond-document" />
          <Text variant="regular" className="mb-0">
            {t('newAtGassan.save')}
          </Text>
        </div>
      </div>
    </div>;
};
const parent = parse({
  mb: '6'
});
const item = parse({
  display: 'grid',
  alignItems: 'center',
  color: 'tabaccoBrown'
}, "i1lhctno");

require("./NewAtGassan.linaria.module.css!=!../node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./NewAtGassan.tsx");