import ServiceSection from './ServiceSection'
import { Box, Container, OldHeading, OldText } from './gassan-ui'
import { FloatingServiceMenu, Footer, Header, Page, PageTransition } from '@components/Page'
import { buildLanguageAlternateNavigation } from '@lib/build-language-alternates'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import React, { useEffect } from 'react'

interface Props {
  statusCode?: number | null
}

const ErrorPage: React.FC<Props> = ({ statusCode }) => {
  const { t } = useTranslation('other')
  const { locales } = useRouter()
  useEffect(() => {
    console.error(`Statuscode: ${statusCode}`)
  }, []) //eslint-disable-line
  return (
    <Page languageAlternates={buildLanguageAlternateNavigation(locales, null)}>
      <FloatingServiceMenu />
      <Header initialVariant="absolute" />
      <PageTransition>
        <Container
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          minHeight={300}
        >
          <Box
            maxWidth={{ medium: '33rem', large: '43rem' }}
            textAlign="center"
            pt={{ _: '3rem', large: '8rem', xlarge: '11.5' }}
            pb={{ _: '3rem', large: '6rem', xlarge: '8.5' }}
          >
            <OldHeading as="h1" variant="h1" mb="1.5rem">
              {t('errorPageTitle')}
            </OldHeading>
            <OldText style={{ whiteSpace: 'pre-line' }}>
              {statusCode === 404 ? t('errorPageDescription404') : t('errorPageDescriptionGeneric')}
            </OldText>
          </Box>
          <ServiceSection bg="white" />
        </Container>
        <Footer />
      </PageTransition>
    </Page>
  )
}

export default ErrorPage
