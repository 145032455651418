import LocalizedLink from '@components/Localization/LocalizedLink'
import { Container, OldHeading, Icon, Image, Span } from '@gassan-ui'
import { cn } from '@lib/cn'
import { Item, getKontentImage } from '@lib/kontent'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

const Blocks: FC = () => {
  const { t } = useTranslation('footer-items')
  return (
    <Container className="grid lg:grid-cols-3 lg:gap-6">
      {t<string, Item[]>('blocks', { returnObjects: true })
        .slice(0, 3)
        .map((block) => (
          <LocalizedLink href={`/${block.elements.url.value}`} key={block.elements.url.value}>
            <a
              className={cn(
                'group border-t border-shade-300 transition-all duration-300 last:border-b',
                'lg:block lg:border-t-0 lg:hover:underline',
              )}
              href={block.elements.url.value}
              title={block.elements.title.value}
            >
              <OldHeading
                variant="h3"
                className={cn(
                  '!mb-0 flex h-16 w-full items-center justify-between self-center',
                  'md:h-24',
                  'lg:!mb-6 lg:h-auto',
                )}
              >
                <span>{block.elements.title.value}</span>
                <Span className="hidden sm:contents lg:hidden">
                  <Icon icon={'arrow-right'} />
                </Span>
                <Span className="contents sm:hidden lg:hidden">
                  <Icon icon={'chevron-right'} />
                </Span>
              </OldHeading>
              <div className="hidden transition-all duration-100 group-hover:opacity-80 lg:block">
                <Image
                  placeholderSrc={getKontentImage(block.elements.image?.value[0]?.url, {
                    width: 50,
                  })}
                  sources={[getKontentImage(block.elements.image?.value[0]?.url, { width: 650 })]}
                  alt={block.elements.image?.value[0]?.description}
                  className="aspect-[5/3]"
                />
              </div>
            </a>
          </LocalizedLink>
        ))}
    </Container>
  )
}

export default Blocks
