// @ts-strict-ignore
import { breakpoints, theme } from '../theme'
import { StyledTextProps, allStyledSystemFunctions } from './config'
import { bodyRegularStyle } from './styles'
import shouldForwardProp from '@styled-system/should-forward-prop'
import React, { FC, HTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

const defaultStyles = css`
  font-family: ${theme.fonts.body};
`

export const linkStyles = css`
  transition: color 0.2s ease-in-out;
  color: ${(p) => p.theme.colors.tabaccoBrown};
  text-decoration: underline;
  outline: none;
  font-size: inherit;
`

export const checkmarkListStyles = css`
  position: relative;
  min-height: 2rem;
  list-style-image: url(/images/checkmark-small.svg);
  padding-left: 0.5rem;
  list-style-position: inside;
  @media screen and (min-width: ${breakpoints.small}) {
    list-style-image: url(/images/checkmark.svg);
  }
`

const listStyles = css`
  position: relative;
  list-style-image: url(/images/bullet-small.svg);
  padding-left: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  list-style-position: outside;
  @media screen and (min-width: ${breakpoints.small}) {
    list-style-image: url(/images/bullet.svg);
  }
`

export const regular = css`
  ${bodyRegularStyle};
  line-height: 1.6;
  a {
    ${linkStyles}
  }
  li {
    ${listStyles};
  }
`

export const large = css`
  ${defaultStyles};
  font-size: 1rem;
  line-height: 1.5;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1.125rem;
  }
  @media screen and (min-width: ${breakpoints.large}) {
    font-size: 1.25rem;
  }

  a {
    ${linkStyles}
  }
  li {
    ${listStyles};
  }
`

export const regularBold = css`
  ${defaultStyles};
  font-size: 0.875rem;
  line-height: 1.6;
  font-weight: 600;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 1rem;
  }
  a {
    ${linkStyles}
  }
  li {
    ${listStyles};
  }
`
export const small = css`
  ${defaultStyles};
  font-size: 0.625rem;
  line-height: 1.45;
  letter-spacing: 0.01em;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 0.875rem;
    line-height: 1.6;
    letter-spacing: 0;
  }
  a {
    ${linkStyles}
  }
  li {
    ${listStyles};
  }
`
export const micro = css`
  ${defaultStyles};
  font-size: 0.625rem;
  line-height: 1.45;
  letter-spacing: 0.01em;

  @media screen and (min-width: ${breakpoints.medium}) {
    font-size: 0.75rem;
  }
  a {
    ${linkStyles}
  }
  li {
    ${listStyles};
  }
`

const textStyles = {
  large,
  regular,
  regularBold,
  small,
  micro,
}

export type OldTextProps = HTMLAttributes<HTMLParamElement> &
  StyledTextProps & {
    variant?: 'large' | 'regular' | 'regularBold' | 'small' | 'micro'
  }

export const OldText: FC<OldTextProps> = styled<React.FC<OldTextProps>>(
  React.forwardRef((props: OldTextProps, ref: any) => {
    let As = props.as || 'p'
    return <As {...props} ref={ref}></As>
  }),
).withConfig({ shouldForwardProp })(
  (props) => props.variant && textStyles[props.variant],
  allStyledSystemFunctions,
)

OldText.defaultProps = {
  variant: 'regular',
  mb: 3,
  color: 'shades.800',
}
