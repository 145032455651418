import { cn } from '@lib/cn'
import { FC, HTMLAttributes } from 'react'

export type TextVariant = 'regular' | 'large' | 'regular-bold' | 'small' | 'micro' | 'nav-menu-link'
export type TextAs = 'div' | 'span' | 'p' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5'

type TextProps = HTMLAttributes<HTMLElement> & {
  as?: TextAs
  variant?: TextVariant
}

export const defaultStylingNestedTextElements = cn(
  'font-body text-shade-800 mb-4',
  '[&>a]:text-tabaccoBrown [&>a]:underline [&>a]:transition-colors [&>a]:duration-200 [&>a]:ease-in-out [&>a]:outline-none [&>a]:inherit',
  '[&>li>a]:text-tabaccoBrown [&>li>a]:underline [&>li>a]:transition-colors [&>li>a]:duration-200 [&>li>a]:ease-in-out [&>li>a]:outline-none [&>li>a]:inherit',
  '[&>li]:relative [&>li]:min-h-8 [&>li]:list-inside [&>li]:pl-4 [&>li]:bg-[url(/images/bullet-small.svg)] sm:[&>li]:bg-[url(/images/bullet.svg)] [&>li]:bg-left [&>li]:bg-no-repeat [&>li]:list-none',
)

export const variantTextStyles: Record<TextVariant, string> = {
  regular: 'text-sm leading-[1.6] md:text-base',
  large: 'text-base leading-[1.5] md:text-lg lg:text-xl',
  'regular-bold': 'text-sm leading-[1.6] font-semibold md:text-base',
  small:
    'text-[0.625rem] leading-[1.45] tracking-[0.01em] md:text-sm md:leading-[1.6] md:tracking-normal',
  micro: 'text-[0.625rem] leading-[1.45] tracking-[0.01em] md:text-xs',
  'nav-menu-link': 'text-[14px] leading-[1.45] tracking-[0.01em] sm:text-[20px] xl:text-[24px]',
}

export const Text: FC<TextProps> = ({
  children,
  className,
  variant = 'regular',
  as = 'p',
  ...rest
}) => {
  const Element = as
  return (
    <Element
      className={cn(variantTextStyles[variant], defaultStylingNestedTextElements, className)}
      {...rest}
    >
      {children}
    </Element>
  )
}
