import FooterTrustPilotWidget from './TrustPilotWidget'
import { getSupportedPaymentImageSources } from './helpers/get-supported-payment-image-sources'
import { Box, Container, Flex, OldHeading, breakpoints } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { rem } from 'polished'
import { FC } from 'react'
import styled from 'styled-components'

const partners = ['/logos/fedex.png', '/logos/postnl.png']
const Row = styled(Flex)`
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.large}) {
    flex-direction: row;
    width: 50%;
    div {
      margin-right: 1rem;
    }
  }
`

const FirstRow = styled(Row)`
  img {
    margin: 0 0.5rem;
  }
  margin-bottom: 2.5rem;
  @media screen and (min-width: ${breakpoints.large}) {
    display: block;
    width: 50%;
    margin-bottom: 0;
    img {
      margin-left: 0;
      margin-right: 1rem;
    }
  }
  @media screen and (min-width: ${breakpoints.xlarge}) {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    div {
      margin-right: 1rem;
    }
  }
`

const LastRow = styled(Row)`
  width: 100%;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column;

  @media screen and (min-width: ${breakpoints.large}) {
    display: block;
    div {
      margin-right: 1rem;
    }
  }

  @media screen and (min-width: ${breakpoints.xlarge}) {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    width: 50%;
  }
`

const ImageRow = styled(Flex)`
  justify-content: center;
  flex-wrap: wrap;
  max-width: 80%;
  margin: 0 auto;
  @media screen and (min-width: ${breakpoints.large}) {
    max-width: 100%;
    justify-content: flex-start;
    margin-left: 0;
    margin-right: 0;
  }
`

const Img = styled.img`
  height: ${rem(60)};
  width: ${rem(60)};
  object-fit: contain;
  @media screen and (min-width: ${breakpoints.xlarge}) {
    justify-content: flex-start;
  }
`
type Props = {}

const SubFooter: FC<Props> = () => {
  const {
    t,
    i18n: { language },
  } = useTranslation('footer')
  const supportedPaymentMethodSources = getSupportedPaymentImageSources(language as string)

  return (
    <Box
      borderTopWidth={1}
      borderTopStyle="solid"
      borderTopColor="shades.200"
      pt={['4rem', '4rem', '3.5rem', '3.5rem', '1.5rem']}
      pb={['1rem', '1rem', '1rem', '3.5rem', '1.5rem']}
    >
      <Container display="flex" flexWrap="wrap">
        <FirstRow>
          <OldHeading variant="h5" as="div" textAlign={['center', 'center', 'left']} mb={0}>
            {t('weAccept')}:
          </OldHeading>
          <ImageRow alignItems="center">
            {supportedPaymentMethodSources.map((src) => (
              <Img src={src} alt="" key={src} loading="lazy" />
            ))}
          </ImageRow>
        </FirstRow>
        <LastRow>
          <OldHeading variant="h5" as="div" textAlign={['center', 'center', 'left']} mb={0}>
            {t('ourPartners')}:
          </OldHeading>
          <ImageRow alignItems="center">
            {partners.map((src) => (
              <Img src={src} alt="" key={src} loading="lazy" />
            ))}
            <FooterTrustPilotWidget />
          </ImageRow>
        </LastRow>
      </Container>
    </Box>
  )
}

export default SubFooter
