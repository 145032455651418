import Modal from './Modal'
import { Button, OldHeading, OldText } from '@gassan-ui'
import { cn } from '@lib/cn'
import { acceptAllCookies } from '@lib/cookies'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

const Notice: FC = () => {
  const [isCookiePopupVisible, setIsCookiePopupVisible] = useState(false)
  const { t } = useTranslation('cookies')

  const acceptAll = () => {
    acceptAllCookies()
    window.location.reload()
  }
  return (
    <>
      <div
        className={cn(
          'fixed bottom-6 left-6 right-6 z-20 border-t border-bigStone bg-pampasLight p-5',
          'border-t-2 p-8 sm:bottom-8 sm:left-8 sm:right-auto sm:max-w-sm',
          'lg:bottom-12 lg:left-12',
          'xxl:bottom-20 xxl:left-20',
        )}
      >
        {/* override defaults in Heading and Text, can be removed later on */}
        <OldHeading className="!mb-2 sm:!mb-4" variant="h5">
          {t('noticeTitle')}
        </OldHeading>
        <OldText className="!text-shade-600" variant="micro">
          {t('noticeMsg')}
        </OldText>

        <Button
          variant="outline"
          className="mb-2 min-w-full"
          onClick={() => setIsCookiePopupVisible(true)}
        >
          {t('noticeMsgSettings')}
        </Button>
        <Button variant="dark" className="min-w-full" onClick={() => acceptAll()}>
          {t('acceptAll')}
        </Button>
      </div>
      {isCookiePopupVisible && <Modal onClose={() => setIsCookiePopupVisible(false)} />}
    </>
  )
}

export default Notice
