import { CleanButton, Icon } from '@components/gassan-ui'
import { Heading } from '@components/gassan-ui/Typography/Heading'
import { cn } from '@lib/cn'
import * as Dialog from '@radix-ui/react-dialog'
import { FC } from 'react'

export type ModalProps = Dialog.DialogProps & {
  triggerButton?: React.ReactNode
  title: string
}

export const Modal: FC<ModalProps> = ({
  children,
  onOpenChange,
  open,
  defaultOpen,
  triggerButton,
  title,
}) => {
  return (
    <Dialog.Root defaultOpen={defaultOpen} open={open} onOpenChange={onOpenChange}>
      {triggerButton && <Dialog.Trigger asChild>{triggerButton}</Dialog.Trigger>}

      <Dialog.Portal>
        <Dialog.Overlay className="fixed inset-0 z-[100] h-screen w-screen bg-shade-800/30">
          <Dialog.Content
            className={cn(
              'relative left-0 top-0 h-screen w-screen overflow-y-auto bg-white',
              'left-[50%] top-[50%] -translate-x-1/2 -translate-y-1/2 md:h-auto md:max-h-[80vh] md:max-w-[32rem]',
            )}
          >
            <div
              className={cn(
                'sticky top-0 z-10 mb-4 flex items-center justify-between border-b border-b-shade-200 bg-white p-4',
                'md:relative md:border-b-0 md:p-8',
              )}
            >
              <Heading variant="h3" as="h3" className="mb-0">
                {title}
              </Heading>
              <Dialog.Close asChild>
                <CleanButton>
                  <Icon icon="cross" />
                </CleanButton>
              </Dialog.Close>
            </div>

            <div className={cn('px-4 pb-[100px]', 'md:px-8 md:pb-8')}>{children}</div>
          </Dialog.Content>
        </Dialog.Overlay>
      </Dialog.Portal>
    </Dialog.Root>
  )
}
