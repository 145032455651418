// @ts-strict-ignore
import Subrow from '../Subrow'
import { linkStyle, subrowButton } from '../Subrow.styles'
import { SUBROW_HEIGHT_MEDIUM, SUBROW_HEIGHT_SMALL } from '../utils/config'
import { getSubrowAnimation } from '../utils/get-subrow-animation'
import { useResponsiveRowHeight } from '../utils/useResponsiveRowHeight'
import Diamonds from './Diamonds'
import { Jewelry } from './Jewelry'
import { Watches } from './Watches'
import WeddingRings from './WeddingRings'
import LocalizedLink from '@components/Localization/LocalizedLink'
import { Heading } from '@components/gassan-ui/Typography/Heading'
import { NavigationContent } from '@lib/kontent/navigation-helpers/normalize-navigation-content'
import { cx } from '@linaria/core'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC, useState } from 'react'

type Props = {
  content: NavigationContent
}

const Shop: FC<Props> = ({ content }) => {
  const [active, setActive] = useState(null)
  const rowHeight = useResponsiveRowHeight(SUBROW_HEIGHT_SMALL, SUBROW_HEIGHT_MEDIUM)
  const margin = useResponsiveRowHeight(0, 16)
  const { t } = useTranslation(['other', 'navigation', 'dropdown'])
  const toggle = (index) => setActive(index === active ? null : index)
  return (
    <>
      <Subrow
        isActive={active === 0}
        label={t('mainNav.watches', { ns: 'navigation' })}
        onClick={() => toggle(0)}
        {...getSubrowAnimation({ index: 0, active, height: rowHeight, margin })}
      >
        <Watches {...content.watches} />
      </Subrow>
      <Subrow
        isActive={active === 1}
        label={t('mainNav.jewelry', { ns: 'navigation' })}
        onClick={() => toggle(1)}
        {...getSubrowAnimation({ index: 1, active, height: rowHeight, margin })}
      >
        <Jewelry {...content.jewelry} />
      </Subrow>
      <Subrow
        isActive={active === 2}
        label={t('mainNav.diamonds', { ns: 'navigation' })}
        onClick={() => toggle(2)}
        {...getSubrowAnimation({ index: 2, active, height: rowHeight, margin })}
      >
        <Diamonds {...content.diamonds} />
      </Subrow>
      <Subrow
        isActive={active === 3}
        label={t('mainNav.weddingRings', { ns: 'navigation' })}
        onClick={() => toggle(3)}
        {...getSubrowAnimation({ index: 3, active, height: rowHeight, margin })}
      >
        <WeddingRings {...content.weddingRings} />
      </Subrow>
      <LocalizedLink href={t('accessories', { ns: 'navigation' })} passHref>
        <motion.a
          className={cx(subrowButton, linkStyle)}
          {...getSubrowAnimation({ index: 4, active, height: rowHeight, margin })}
        >
          <Heading variant="h2" as="h2" className="mb-0">
            className="mb-0"
            {t('shop.accessories', { ns: 'dropdown' })}
          </Heading>
        </motion.a>
      </LocalizedLink>
      <LocalizedLink href={t('giftcards', { ns: 'navigation' })} passHref>
        <motion.a
          className={cx(subrowButton, linkStyle)}
          {...getSubrowAnimation({ index: 4, active, height: rowHeight, margin })}
        >
          <Heading variant="h2" as="h2" className="mb-0">
            {t('shop.giftcard', { ns: 'dropdown' })}
          </Heading>
        </motion.a>
      </LocalizedLink>
    </>
  )
}

export default Shop
