// @ts-strict-ignore
import { getNavigationInLocale } from '@lib/get-navigation-in-locale'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'

export const buildLanguageAlternateNavigation = (
  locales,
  key,
  prefix: string | null = null,
  suffix: string | null = null,
) => {
  return locales.map((lang) => {
    const base = lang !== 'nl' ? `/${lang}` : ''

    return {
      hrefLang: lang,
      href: `${base}${prefix ?? ''}${key ? getNavigationInLocale(key, lang) : ''}${suffix ?? ''}`,
    }
  })
}

export const buildLanguageAlternateSlugItems = (locales, key, prefix = null, suffix = null) => {
  return locales.map((lang) => {
    const base = lang !== 'nl' ? `/${lang}` : ''
    return {
      hrefLang: lang,
      href: `${base}/${prefix ?? ''}${key ? getUrlSlugInLocale(key, lang) : ''}${suffix ?? ''}`,
    }
  })
}
