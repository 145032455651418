import { Box, Container, Grid, OldHeading, OldText, breakpoints } from '@gassan-ui'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'
import styled from 'styled-components'
import { BackgroundColorProps } from 'styled-system'

const ContactBlock = styled.div`
  background: ${(p) => p.theme.colors.pampas};
  padding: 1rem;
  width: 100%;
  max-width: 29rem;

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 2rem;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    max-width: inherit;
  }

  a {
    display: inline-block;
  }
`

type Props = BackgroundColorProps & {
  title?: string
}

const ServiceSection: FC<Props> = ({ title, bg = 'shades.50' }) => {
  const { t } = useTranslation('global-items')
  return (
    <Box position="relative">
      <Box
        position="absolute"
        top="0"
        left="0"
        height={{ _: 'calc(100% - 6rem)', large: 'calc(100% - 3.5rem)' }}
        bg={bg}
        width="100%"
        zIndex={-1}
      />
      <Container pt={{ _: '2rem', small: '3.5rem' }}>
        {title && (
          <OldHeading
            variant="h3"
            as="h3"
            mb={{ _: '1.5rem', small: '2.5rem', large: '3rem' }}
            textAlign="center"
          >
            {title}
          </OldHeading>
        )}
        <Grid
          gridTemplateColumns={{ _: '1fr', large: '1fr 1fr' }}
          gridColumnGap={{ _: '1.5rem', xlarge: '3.5rem' }}
          maxWidth="750px"
          justifyItems="center"
          m="0 auto"
          gridRowGap={['1rem', '1.5rem']}
        >
          <ContactBlock>
            <OldText variant="small">{t('contactTitle')}</OldText>
            <OldText
              lineHeight="2"
              as="div"
              mb="0"
              dangerouslySetInnerHTML={{ __html: t('contactInfo') }}
            />
          </ContactBlock>
          <ContactBlock>
            <OldText variant="small">{t('emailTitle')}</OldText>
            <OldText as="div" mb="0" dangerouslySetInnerHTML={{ __html: t('emailInfo') }} />
          </ContactBlock>
        </Grid>
      </Container>
    </Box>
  )
}

export default ServiceSection
