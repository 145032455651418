// @ts-strict-ignore
import { MenuLabel } from '../MenuLabel'
import * as styles from './NoResults.styles'
import Result from './Result'
import { Text } from '@components/gassan-ui/Typography/Text'
import { LinkTranslated } from '@components/types'
import { getKontentImage } from '@lib/kontent'
import { cx } from '@linaria/core'
import { motion } from 'framer-motion'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type Props = {}

const NoResults: FC<Props> = () => {
  const { t } = useTranslation(['dropdown', 'dropdown-items', 'global-items'])
  return (
    <motion.div animate={{ opacity: 1 }} initial={{ opacity: 0 }} exit={{ opacity: 0 }}>
      <div>
        <Text variant="large" className={styles.title}>
          {t('search.notFound', { ns: 'dropdown' })}
        </Text>
        {t<string, LinkTranslated[]>('search.notFoundPages', {
          ns: 'dropdown-items',
          returnObjects: true,
        }).map((page) => (
          <Result
            key={page.urlSlug}
            title={page.title}
            image={getKontentImage(page.image, { width: 100, height: 100 })}
            href={page.urlSlug}
            variant="brand"
          ></Result>
        ))}
      </div>
      <div className={styles.grid}>
        <div className={cx(styles.column, styles.firstColumn)}>
          <MenuLabel>{t('service.customerService', { ns: 'dropdown' })}</MenuLabel>
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: t('contactInfo', { ns: 'global-items' }) }}
          ></Text>
        </div>
        <div className={cx(styles.column, styles.secondColumn)}>
          <MenuLabel>{t('emailTitle', { ns: 'global-items' })}</MenuLabel>
          <Text
            as="div"
            dangerouslySetInnerHTML={{ __html: t('emailInfo', { ns: 'global-items' }) }}
          ></Text>
        </div>
      </div>
    </motion.div>
  )
}

export default NoResults
